import React from 'react';
import OutlinedButton from './OutlinedButton';
import '../css/coming-soon-banner.css'; 

const ComingSoonBanner = ({ header, subheader, buttonText, buttonLink }) => {
    return (
        <div className="coming-soon-banner-container">
            <div className="coming-soon-header">{header}</div>
            {subheader && <div className="coming-soon-subheader">{subheader}</div>}
            <div className="coming-soon-button-container">
                <OutlinedButton text={buttonText} linkTo={buttonLink} style={{ width: "inherit"}}/>
            </div>
        </div>
    );
}
 
export default ComingSoonBanner;